<template>
	<div>
		<b-card no-body>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<b-col cols="12" md="3" lg="3" sm="6">
						<b-form-group label="Type" label-for="type" class="mb-0">
							<v-select v-model="search.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:clearable="false" :options="typeOptions" :reduce="option => option.value" />
						</b-form-group>
					</b-col>

					<b-col cols="12" md="3" lg="3" sm="6">
						<b-form-group label="Platform" label-for="platform" class="mb-0">
							<v-select v-model="search.platform" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:clearable="false" :options="platformOptions" :reduce="option => option.value" />
						</b-form-group>
					</b-col>

					<b-col cols="12" md="3" lg="3" sm="6">
						<b-form-group label="Status" label-for="status" class="mb-0">
							<v-select v-model="search.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:clearable="false" :options="statusOptions" :reduce="option => option.value" />
						</b-form-group>
					</b-col>
					<b-col cols="12"md="3" lg="3" sm="6" class="d-flex align-items-end justify-content-end">
						<b-button variant="outline-primary" @click="resetFilter()" :disabled="loading">
							Reset
						</b-button>
						<b-button variant="primary" class="ml-2" @click="searchFilter()" :disabled="loading">
							Search
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<div v-if="loading" class="text-center mt-2">
				<b-spinner class="align-middle"></b-spinner>
			</div>
		<b-card v-else no-body class="mb-0">
			<div class="p-1">
				<b-row>
					<b-col v-if="$can('read', 'banneradd')" class="col d-flex justify-content-end">
						<b-button variant="primary" @click="addBanner">
							<feather-icon icon="PlusIcon" size="16" /> {{ $t('New') }}
						</b-button>
					</b-col>
				</b-row>

			</div>
			
			<b-table ref="refBannersListTable" class="position-relative table-white-space" :items="bannerList" responsive
				:fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
				<template #cell(index)="data">
					{{ data.index + 1 }}
				</template>

				<template #cell(type)="data">
					{{ resolveType(data.item.type) }}
				</template>

				<template #cell(platform)="data">
					{{ resolvePlatform(data.item.platform) }}
				</template>

				<template #cell(images)="data">
					<b-link :href="baseUrl + data.item.url" target="_blank" class="cursor-pointer">
						<b-img :src="baseUrl + data.item.url" fluid class="mr-2 rounded" />
					</b-link>
				</template>

				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{
							resolveStatus(data.item.status).label }}
					</div>
				</template>

				<template #cell(created_at)="data">
					{{ formatDateTime(data.item.created_at).replace('.000000Z', '') }}
				</template>

				<template #cell(updated_at)="data">
					{{ formatDateTime(data.item.updated_at).replace('.000000Z', '') }}
				</template>

				<template #cell(action)="data">
					<b-button v-if="$can('update', 'banner')" variant="outline-primary" class="btn-icon btn-sm mr-50"
						@click="updateBanner(data.item)">
						<feather-icon icon="EditIcon" />
					</b-button>
					<b-button v-b-tooltip.hover.v-danger :title="$t('Reject')" v-if="$can('delete', 'banner')"
						variant="danger" class="btn-icon btn-sm" @click="deleteBanner(data.item.id)">
						<feather-icon icon="XIcon" />
					</b-button>
				</template>

			</b-table>

			<div class="mx-2 mb-0">
				<b-row>

					<b-col cols="12" sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
							entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination v-model="currentPage" :total-rows="totalBanners" :per-page="perPage" first-number
							last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<!-- Add Banner Modal -->
		<add-banner-modal @refetch-data="refetchData" />

		<!-- Add Banner Modal -->
		<update-banner-modal @refetch-data="refetchData" :banner-detail.sync="bannerDetail" :bannerDetail="bannerDetail" />

	</div>
</template>

<script>
import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BImg, VBTooltip, BFormGroup, BSpinner
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, numberFormat, formatDateTime } from '@core/utils/filter'
import useBannersList from './useBannersList'
import frontendStoreModule from '../frontendStoreModule'
import baseUrl from '@/libs/base-url';
import i18n from '@/libs/i18n';
import vSelect from 'vue-select';

export default {
	components: {
		AddBannerModal: () => import('./AddBannerModal.vue'),
		UpdateBannerModal: () => import('./UpdateBannerModal.vue'),
		BCard,
		BRow,
		BCol,
		BFormInput,
		BFormGroup,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BImg,
		BSpinner,
		vSelect
	},
	directives: {
		'b-tooltip': VBTooltip,
	},
	data() {
		return {
			bannerDetail: null,
		}
	},
	methods: {
		addBanner() {
			this.$bvModal.show('modal-add-banner')
		},
		updateBanner(data) {
			this.bannerDetail = data;
			this.$bvModal.show('modal-update-banner')
		},
		deleteBanner(bannerId) {
			this.$swal({
				title: 'Bạn có chắc không ?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
					const bannerData = {
						bannerIds: bannerId,
					}
					store.dispatch('frontend/deleteBanner', bannerData)
						.then(response => {
							if (response.data.code == '00') {
								this.$swal({
									icon: 'success',
									title: 'Deleted!',
									text: 'Your file has been deleted.',
									customClass: {
										confirmButton: 'btn btn-success',
									},
								})
								this.refetchData()
							}
						})
				}
			})
		},
	},
	setup() {
		const FRONTEND_MODULE_NAME = 'frontend'
		if (!store.hasModule(FRONTEND_MODULE_NAME)) store.registerModule(FRONTEND_MODULE_NAME, frontendStoreModule)
		onUnmounted(() => {
			if (store.hasModule(FRONTEND_MODULE_NAME)) store.unregisterModule(FRONTEND_MODULE_NAME)
		});
		const {
			fetchBanners,
			tableColumns,
			perPage,
			currentPage,
			totalBanners,
			dataMeta,
			refBannersListTable,
			refetchData,
			resolveStatus,
			resolveType,
			resolvePlatform,
			search,
			bannerList,
			loading
		} = useBannersList();

		fetchBanners();

		const statusOptions = [
			{ label: i18n.t('Active'), value: 1 },
			{ label: i18n.t('Inactive'), value: 2 },
		];

		const typeOptions = [
			{ label: i18n.t('Home'), value: 1 },
			{ label: i18n.t('Promotion'), value: 2 },
		];

		const platformOptions = [
			{ label: i18n.t('Desktop'), value: 1 },
			{ label: i18n.t('Mobile'), value: 2 },
		];

		const searchFilter = () => {
			fetchBanners();
		}

		const resetFilter = () => {
			search.value = {
				type: "",
				platform: "",
				status: "",
			};
		};

		return {
			fetchBanners,
			tableColumns,
			perPage,
			currentPage,
			totalBanners,
			dataMeta,
			refBannersListTable,
			refetchData,
			resolveStatus,
			resolveType,
			resolvePlatform,
			numberFormat,
			formatDateTime,
			baseUrl,
			search,
			statusOptions,
			typeOptions,
			platformOptions,
			searchFilter,
			resetFilter,
			bannerList,
			loading
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import "@core/scss/vue/libs/vue-select.scss";
</style>