import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function useBannersList() {
	const refBannersListTable = ref(null)

	const tableColumns = [
		{ key: 'index', label: '#' },
		// { key: 'name', label: i18n.t('Name') },
		{ key: 'type', label: i18n.t('Type') },
        { key: 'images', label: i18n.t('Banners') },
		{ key: 'platform', label: i18n.t('Platform') },
		{ key: 'link', label: i18n.t('Link') },
		{ key: 'status', label: i18n.t('Status') },
		{ key: 'created_by', label: i18n.t('Created by') },
		{ key: 'created_at', label: i18n.t('Created at') },
		{ key: 'updated_at', label: i18n.t('Updated at') },
		{ key: 'action', label: i18n.t('Actions') },
	]
	const perPage = ref(25)
	const totalBanners = ref(0)
	const currentPage = ref(1);
	const bannerList = ref([]);
	const loading = ref(false);

	const search = ref({
		type: '',
		platform: '',
		status: ''
	})

	const refetchData = () => {
		fetchBanners();
	}

	watch([currentPage], () => {
		fetchBanners()
	})

	const fetchBanners = (ctx, callback) => {
		loading.value = true;
		store
			.dispatch('frontend/fetchBanners', {
				page: currentPage.value,
				type: search.value.type,
				platform: search.value.platform,
				status: search.value.status,
			})
			.then(response => {
				if (response.data.code == '00') {
					// callback(response.data.data)
					bannerList.value = response.data.data
					totalBanners.value = response.data.count
					perPage.value = currentPage.value == 1 ? response.data.data.length : 25;
					loading.value = false;
				}
			})
	}

	const dataMeta = computed(() => {
		const localItemsCount = refBannersListTable.value ? refBannersListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalBanners.value,
		}
	})

	const resolveStatus = status => {
		if (status === 1) return { label: i18n.t('Active'), variant: 'success' }
		if (status === 2) return { label: i18n.t('Inactive'), variant: 'danger' }
	}

	const resolveType = type => {
		if (type === 1) return 'Home'
		if (type === 2) return 'Promotion'
		return 'None'
	}

	const resolvePlatform = type => {
		if (type === 1) return 'Desktop'
		if (type === 2) return 'Mobile'
		return 'Unknown'
	}

	return {
		fetchBanners,
		tableColumns,
		perPage,
		currentPage,
		totalBanners,
		dataMeta,
		refBannersListTable,
		refetchData,
		resolveStatus,
		resolvePlatform,
		resolveType,
		search,
		bannerList,
		loading
	}
}
